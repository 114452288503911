import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from '@material-ui/core/Typography';
// import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';

import AllGPData from './ui/AllGPData';

// import book5 from '../assets/gp-success/book5.jpg';
// import mbook5 from '../assets/gp-success/mbook5.jpg';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 0,
  },
  fix: {
    position: "fixed",
    top: "50%",
    marginTop: "-100px",
  },
  imgContainer: {
    width: "100%",
  },
  bookImg: {
    height: "100%",
    width: "auto",
    position: "fixed",

  },
  storyContainer: {
    padding: "3em 10em",
    backgroundColor: theme.palette.common.greyLight,
    [theme.breakpoints.down("md")]: {
      padding: "3em 3em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3em 1.5em",
    }
  },
  a: {
    color: theme.palette.common.color1,
    textDecoration: "none",
  },
}));

export default function GPWinnerDetailsPage(props) {

  let propsID = 0;
  if (props.location.state !== undefined) {
    propsID = props.location.state.id;
  }
  console.log(propsID);

  const gpID = propsID;
  const gpArray = AllGPData();

  let data = gpArray.find((element) => {
      return element.id === gpID;
    });

  if (data === undefined) {
    data = {
      id: -1,
      name: "404 - No Data Found",
      desc: "",
      avatar: require('../../assets/imagePlaceHolder.png'),
      gpImgLaptop: require('../../assets/gp-success/book5.jpg'),
      gpImgMobile: require('../../assets/gp-success/mbook5.jpg'),
      content: "",
      link: "/GP-Success-Stories/GP-Details",
    }
  }

  console.log(data);

  const classes = useStyles(data);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down(850));

  return (
    <Grid container>
      {matches ? "" :
      <Grid xs={matches ? false : 4} item className={classes.fix}>
        <img src={data.gpImgLaptop.default} alt="" className={classes.imgContainer}/>
      </Grid>
      }
      <Grid container direction={matches ? "column" : "row"} className={classes.mainContainer}>
        <Grid xs={matches ? false : 4} item>
          {matches ? <img src={data.gpImgMobile.default} alt="" className={classes.imgContainer}/> : ""}
        </Grid>
        <Grid xs={matches ? false : 8} item className={classes.storyContainer}> {/*----------Select Year Block-----------*/}
          <Typography align="center" variant="h2"><span style={{color: theme.palette.common.color1}}>{data.name}</span></Typography>
          <br/> <br/>
          <Typography>
            {data.content}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
