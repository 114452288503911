import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@material-ui/icons/GetApp';

//PDF files
import PDF2 from "../../res/c-tools/gu/ગુજરાત સરકાર જીપીડીપી માર્ગદર્શિકા 30 ડિસેમ્બર 2015.pdf";
import PDF3 from "../../res/c-tools/gu/ઉન્નતિ જીપીડીપી માર્ગદર્શિકા અને પત્રકો 14 Oct 2019.pdf";
import PDF4 from "../../res/c-tools/gu/Gujarati Health Education Social Security Schemes booklet.pdf";
import PDF5 from "../../res/c-tools/gu/Gujarati Village Level Committees roles.pdf";


const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 0,
  },
  contactContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  tableContainer: {
    width: "80%",
    marginLeft: "10em",
    marginRight: "10em",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2em",
      marginRight: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1.5em",
      marginRight: "1.5em",
    }
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  a: {
    color: theme.palette.common.color1,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(filename, url, date, size) {
  return { filename, url, date, size };
}

const rows = [
  createData('ઉન્નતિ જીપીડીપી માર્ગદર્શિકા અને પત્રકો 14 Oct 2019', PDF3, "Oct 14, 2019", "344 KB"),
  createData('શિક્ષણ આરોગ્ય અને સામાજિક સુરક્ષાની યોજનાઓ 2019', PDF4, "2019", "11,959 KB"),
  createData('ગ્રામ પંચાયત અંતર્ગત સમિતિઓની રચના અંગે માર્ગદર્શિકા 2019', PDF5, "2019", "6,906 KB"),
  createData('ગુજરાત સરકાર જીપીડીપી માર્ગદર્શિકા 30 ડિસેમ્બર 2015', PDF2, "Dec 30, 2015", "845 KB"),
];

export default function GPGuidlinesPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (

    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item className={classes.contactContainer}> {/*----------Contact Us Block-----------*/}
        <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>ગ્રામ પંચાયત માર્ગદર્શિકા અને સાધનો</span></Typography>
        <br/><br/>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">ડાઉનલોડ</StyledTableCell>
              <StyledTableCell width="60%">ફાઈલનું નામ</StyledTableCell>
              <StyledTableCell align="right" width="20%">તારીખ</StyledTableCell>
              <StyledTableCell align="right" width="15%">સાઈઝ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.filename}>
                <StyledTableCell>
                  <IconButton color="primary" aria-label="Download File" className={classes.iconButton} >
                    <a href = {row.url} target = "_blank" rel="noreferrer" className={classes.a}><DownloadIcon /></a>
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell >
                  {row.filename}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.size}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
