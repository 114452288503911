import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import NationalGPData from './ui/TopNationalGPData';

import winner from '../../assets/winner2.svg';

const useStyles = makeStyles(theme => ({
  greyBackground: {
    position: "fixed",
    top: 0,
    backgroundColor: theme.palette.common.greyLight,
    height: "100%",
    width: "100%",
    zIndex: -999,
  },
  mainContainer: {
    marginTop: 0,
  },
  imgHeaderContainer: {
    paddingTop: "0em",
    backgroundColor: theme.palette.common.white,
  },
  imgHeader: {
    height: "200px",
    width: "100%",
    padding: "2em",
    [theme.breakpoints.down("md")]: {
      height: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "150px",
    },
  },
  gpContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 3em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3em 1.5em",
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  cardRoot: {
    maxWidth: 345,
    minWidth: 345,
    margin: theme.spacing(2),
    "&:hover": {
      boxShadow: "0 14px 28px rgba(150, 150, 150, 0.25)"
    },
  },
  cardMedia: {
    height: 220,
  },
}));

export default function GPNationalWinnerPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  const gpArray = NationalGPData();

  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.imgHeaderContainer}> {/*------------Img Header Block----------------*/}
        <img alt="village" src={winner} className={classes.imgHeader}/>
        <Typography align="center" variant="h2"><span style={{color: theme.palette.common.color1}}>રાષ્ટ્રીય એવોર્ડ વિજેતા બાળ મૈત્રીપૂર્ણ ગ્રામ પંચાયત</span></Typography>
      </Grid>
      <Divider light />
      <Grid container direction="row" justify="center" className={classes.gpContainer}>
        {gpArray.map((array, i) => (
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4"><span style={{color: theme.palette.common.color1}}>ગ્રામ પંચાયત: 20{array.year}</span></Typography><br/>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              {
               array.data.map((gpData, i) => (
                 <Grid item md={5} xl={4} key={`${gpData}${i}`}>
                   <Card className={classes.cardRoot}>
                     <CardActionArea component={Link} to={{pathname: gpData.link, state:gpData}}>
                       <CardMedia
                         className={classes.cardMedia}
                         image={gpData.avatar.default}
                         title="GP Image"
                       />
                       <CardContent>
                         <Typography gutterBottom variant="h5" component="h2">
                           {gpData.name}
                         </Typography>
                         <Typography variant="body2" color="textSecondary" component="p">
                           {gpData.desc}
                         </Typography>
                       </CardContent>
                     </CardActionArea>
                     <CardActions>
                       <Button size="small" color="primary" component={Link} to={{pathname: gpData.link, state:gpData}}>
                         વધુ માહિતી માટે
                       </Button>
                     </CardActions>
                   </Card>
                 </Grid>
               ))
              }
            </Grid>
          </Grid>
        </Grid>
        ))}
      </Grid>
      <div className={classes.greyBackground}></div>
    </Grid>
  );
}
