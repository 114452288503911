
export default function FAQuestions() {

  const quesArray = [
    {
      question: "1. એસ.એમ.સી. શું છે અને તેની રચના કેવી રીતે થાય?",
      answer: "એસ.એમ.સી. એટલે કે શાળામાં શિક્ષણના એકંદર સંચાલન માટે \"શાળા સંચાલન સમિતિ\". પ્રાથમિક શાળાના મુખ્ય શિક્ષક 12 સભ્યોની બનેલી એસ.એમ.સી.ની રચના કરવા માટે ગ્રામ સભા અથવા માતા-પિતાની મીટિંગ બોલાવી શકે છે. એસએમસીની ફરીથી રચના દર બે વર્ષે કરવાની હોય છે.",
      panel: "panel1",
      ariaControl: "panel1-content",
      id: "panel1-header",
    },
    {
      question: "2. એસએમસી સભ્યો કોણ બની શકે?",
      answer: "કુલ 12 સભ્યોમાંથી 75% માતા-પિતા, જેમાં વિદ્યાર્થીઓની સંખ્યાના પ્રમાણ મુજબ સીમાંત સમુદાયના માતાપિતાના પ્રતિનિધિઓનો સમાવેશ થાય છે. એસ.એમ.સી.માં કુલ 50% મહિલા સભ્યો હોવા જોઈએ. અન્ય સભ્યોમાં શામેલ છે: 1 શાળામાંથી શિક્ષક (બધા શિક્ષકો દ્વારા પસંદ કરેલ); 1 ચૂંટાયેલા પ્રતિનિધિ, અથવા 1 ટ્રસ્ટ અથવા મેનેજમેન્ટ સભ્ય (ખાનગી શાળાના કિસ્સામાં); 1 ગામનો  કડિયો; અને 1 શિક્ષણ નિષ્ણાત અથવા 1 વિદ્યાર્થી જે એસએમસીના માતાપિતા સભ્યો દ્વારા પસંદ કરવામાં આવેલ હોય.",
      panel: "panel2",
      ariaControl: "panel2-content",
      id: "panel2-header",
    },
    {
      question: "3. વી.એચ.એસ.એન.સી.ની જવાબદારીઓ શું છે?",
      answer: <div>
      <h5>વીએચએસએનસીની જવાબદારીઓ:</h5>
      <ol>
        <li>જરૂરિયાતો ઓળખવી અને ગ્રામ્ય કક્ષાની આરોગ્ય, સ્વચ્છતા અને પોષણ વાર્ષિક યોજના અને બજેટ તૈયાર કરવું,  જે માસિક અને ત્રિમાસિક પ્રવૃત્તિ પ્રમાણે પણ બનાવેલું હોય.</li>
        <li>માસિક અને ત્રિમાસિક પ્રવૃત્તિઓ અને બજેટનું નિરીક્ષણ કરવું અને જરૂરી હોય ત્યાં સુધારાવધારા કરવા. તેમાં આરોગ્ય કેન્દ્ર સેવાઓની પહોંચ અને આપવામાં આવતી સેવાઓનું નિરીક્ષણ પણ શામેલ છે.</li>
        <li>વી.એચ.એસ.એન.સી.ને આપવામાં આવેલ અન-ટાઈડ ફંડનો હિસાબ જાળવવો.</li>
        <li>આરોગ્ય, પોષણ અને સ્વચ્છતાના મુદ્દાઓ પર જાગૃતિ લાવવા અને સમુદાયની પ્રવૃત્તિઓને પ્રોત્સાહન આપવા માસિક અથવા ઋતુ પ્રમાણે ઝુંબેશનું આયોજન.</li>
        <li>વીએચએસએનસી સભ્યો આરોગ્ય કેન્દ્રો પર ઉપલબ્ધ આરોગ્ય કાર્યક્રમ અને સેવાઓ વિશે લોકોને જાગૃત કરશે. દિવાલો પર ચિત્રકામ, શેરી નાટકો, ફિલ્મ શો, પોસ્ટરો અને નિષ્ણાતો સાથે વાર્તાલાપ જેવા આઇ.ઇ.સી. (માહિતી શિક્ષણ સંચાર) દ્વારા જાગરૂકતા પેદા કરી શકાય છે.</li>
        <li>કુપોષણ, એનિમિયા અને અન્ય બીમારીઓ પર નિવારક ક્રિયાઓ કરવી. આ મુદ્દાઓ પર જાગૃતિ ફેલાવવી, ગામમાં સ્વચ્છતા સુનિશ્ચિત કરવી અને આરોગ્ય અને પોષણ સેવાઓ લેવામાં સમુદાયોને સમર્થન આપવું (મદદ કરવી).</li>
        <li>આશા કાર્યકર, આંગણવાડી કાર્યકર અને સહાયક નર્સ મિડવાઇફ (એએનએમ) એવા પરિવારોને પસંદ કરી શકે છે, જે બીપીએલ, એસસી, એસટી, લઘુમતી અને અન્ય રીતે સેવાઓથી વંચિત હોય. ત્યાર બાદ તેમને જાગૃતિ આપી શકે છે અને સેવાઓ સુધી પહોંચવામાં સહાય કરી શકે છે.</li>
      </ol>
      </div>,
      panel: "panel3",
      ariaControl: "panel3-content",
      id: "panel3-header",
    },
    {
      question: "4. પેસા અને તેની સમિતિઓ શું છે?",
      answer: "પંચાયતી રાજ સંસ્થા (પીઆરઆઈ) સિસ્ટમ હેઠળ આદિજાતિ વિસ્તારો માટે અલગ જોગવાઈ છે, જે પંચાયત વિસ્તરણથી અનુસૂચિત ક્ષેત્ર (પેસા) અધિનિયમ 1996 અને રાજ્ય સરકાર દ્વારા અનુગામી કાયદામાં આવરી લેવામાં આવી છે. પેસા હેઠળ ચૂંટાયેલા પ્રતિનિધિઓની ત્રણ સમિતિઓ છે: (1) શાંતિ સમિતિ, (2) સંશાધન આયોજન અને વ્યવસ્થાપન સમિતિ, અને (3) તકેદારી અને દેખરેખ સમિતિ.",
      panel: "panel4",
      ariaControl: "panel4-content",
      id: "panel4-header",
    },
    {
      question: "5. જી.પી.ડી.પી. એટલે શું?",
      answer: "જીપીડીપી એટલે ગ્રામ પંચાયત વિકાસ યોજના. તે સ્થાનિક ગ્રામ ભંડોળ તેમજ રાજ્ય અને કેન્દ્ર સરકારના અનુદાન (ગ્રાન્ટ)થી પ્રાપ્ત સંસાધનો સહિત ગામની તમામ જરૂરિયાતોને આવરી લેતી સમગ્ર ગ્રામ પંચાયત માટેની વાર્ષિક યોજના છે. જીપીડીપી વાર્ષિક ધોરણે તૈયાર કરવામાં આવે છે અને ગ્રામ પંચાયત પાંચ વર્ષનું લાંબા ગાળાનું વિઝન (દ્રષ્ટિ) આયોજન પણ તૈયાર કરી શકે છે.",
      panel: "panel5",
      ariaControl: "panel5-content",
      id: "panel5-header",
    },
    {
      question: "6. બાલ પંચાયત એટલે શું?",
      answer: "બાલ પંચાયત: ગ્રામ પંચાયતની જેમ બાલ પંચાયત એ ગામના તમામ બાળકોની સંસ્થા છે અને તે જીપીડીપી પ્રક્રિયામાં અર્થપૂર્ણ રીતે ફાળો આપી શકે છે. બાલ સંસદ પણ બાલ પંચાયતનો ભાગ  હોઈ શકે છે. બાલ પંચાયતનો મુખ્ય હેતુ બાળકોને અસર કરતા નિર્ણયોમાં ભાગ લેવાના તેમના અધિકારને પૂરો કરવાનો છે, અને તેને સુનિશ્ચિત કરવા માટે ગ્રામ પંચાયત સ્તરે જી.પી.ડી.પી. એ મુખ્ય પ્રક્રિયા અને સાધન છે.",
      panel: "panel6",
      ariaControl: "panel6-content",
      id: "panel6-header",
    },
  ];

  return quesArray;
}
