import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@material-ui/icons/GetApp';

//PDF files
// import PDF1 from "../res/b-Guidelines/Final Guidelines for GPDP 2018 111218_1546336423450.pdf";
import PDF2 from "../res/b-Guidelines/Gujarat Panchayat Act 1993.pdf";
// import PDF3 from "../res/b-Guidelines/Handbook_for_Members_of_VHSNC-English.pdf";
// import PDF4 from "../res/b-Guidelines/MoF - XV FC RLBs_Guidelines_for_2020-21.pdf";
import PDF5 from "../res/c-tools/Central Government GPDP Guidelines 3 October 2018.pdf";
import PDF6 from "../res/c-tools/UNNATI Preparation of GPDP Operating Framework and Template 14 Oct 2019.pdf";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 0,
  },
  contactContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  tableContainer: {
    width: "80%",
    marginLeft: "10em",
    marginRight: "10em",
    marginBottom: "5em",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2em",
      marginRight: "2em",
      marginBottom: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1.5em",
      marginRight: "1.5em",
      marginBottom: "1.5em",
    }
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  a: {
    color: theme.palette.common.color1,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(filename, url, date, size) {
  return { filename, url, date, size };
}

const rows = [
  // createData('MoF - XV FC RLBs_Guidelines_for_2020-21', PDF4, "2020", "1,666 KB"),
  createData('UNNATI Preparation of GPDP Operating Framework and Template 14 Oct 2019', PDF6, "Oct 14, 2019", "1,107 KB"),
  // createData('Final Guidelines for GPDP 2018 111218_1546336423450', PDF1, "Dec 11, 2018", "4,272 KB"),
  createData('Central Government GPDP Guidelines 3 October 2018', PDF5, "Oct 03, 2018", "4,272 KB"),
  createData('Gujarat Panchayat Act 1993', PDF2, "1993", "3,346 KB"),
  // createData('Handbook_for_Members_of_VHSNC-English', PDF3, "", "4,295 KB"),
];

export default function GPGuidlinesPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (

    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item className={classes.contactContainer}> {/*----------Contact Us Block-----------*/}
        <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>Gram Panchyat Guidelines and Tools</span></Typography>
        <br/><br/>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">Download</StyledTableCell>
              <StyledTableCell width="60%">Document Name</StyledTableCell>
              <StyledTableCell align="right" width="20%">Date</StyledTableCell>
              <StyledTableCell align="right" width="15%">Size</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.filename}>
                <StyledTableCell>
                  <IconButton color="primary" aria-label="Download File" className={classes.iconButton} >
                    <a href = {row.url} target = "_blank" rel="noreferrer" className={classes.a}><DownloadIcon /></a>
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell >
                  {row.filename}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.size}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
