import React from "react";
import Typography from '@material-ui/core/Typography';

export default function TopGPDataAll() {
  const avatar = require("../../assets/gp-success/child.png");
  const gpImgL = require("../../assets/gp-success/child1.png");
  const gpImgM = require("../../assets/gp-success/child.png");

  const gpArray = [
    {
      id: 1,
      name: "Punsari GP",
      desc: "Child-friendly and all-round development, winner of national awards",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>20 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Punsari Gram Panchayat (population 6,000) of Taluka Talod is located in Sabarkantha district in the state of Gujarat, India. The village is located at about 36 KM from Himatnagar, the district headquarters, and 80 KM from the state capital, Gandhinagar. <br/><br/>

Known as a smart village, Punsari is equipped with effective and efficient programs ranging from early childhood development to women’s empowerment and to modern infrastructure such as CCTV camera and public announcement system. It has received about 80,000 visitors so far from national and international entities, and is a model of good governance. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong> Punsari has undergone transformation under the leadership of the Sarpanches: Mr. Himanshu Patel (2006-2016) and Sunandaben Patel (2016-to date of visit i.e. 20 October 2020). Social, economic and environmental development has been outstanding as reflected in various awards conferred on the GP. Gram Panchayat Coordination Committee was formed and is active. There were 228 Below Poverty Line (BPL) families in the village in 2011, now reduced to 60. <br/><br/>
There is good governance through CCTV cameras, LED lights on streets, and loudspeakers for public announcement (also playing religious songs and Mahatma Gandhi’s messages daily). In 2006, the GP had Rs. 2 lakh in the bank, and in 2012, it reached a fixed deposit of Rs. 1.5 crore. This was achieved through well managed funds. <br/><br/>
<strong>Awards:</strong> Punsari has received several awards, for example, Best Gram Panchayat of Gujarat (2010-11), Best Sarpanch award by M S University of Vadodara (2012-13), Nirmal Gram Award by Central Government, and many other awards by other states and central governments. <br/><br/>
<strong>Children Nutrition: </strong> Child malnourishment rate is zero percent, achieved by eight well constructed and well run AWCs. All the AWCs have functional toilets with soaps and napkins for the children. <br/><br/>
<strong>Education: </strong> 100% enrolment of all eligible children in schools is ensured every year, and 0% drop out rate is ensured through girls and boys from poor background households adopted by well-to-do families for education support. Atal Express minibus (20 seats) provide free commuting for students to five primary schools (up to Standard 5) and four secondary schools (up to Standard 8). The schools are equipped with the classrooms with CCTV Cameras, well equipped computer labs, books library, playgrounds, mineral water plants, separate toilets for girls and boys (cleaned regularly), and effective midday meal (plus one special meal per month).  <br/><br/>
<strong>Women empowerment: </strong> There are 108 women’s Self-help Groups (SHGs) with a total of 1,200 membership; 90 SHGs linked with banks for loans and have revolving funds. Training programs were organised in sewing, henna (Mahendi), beauty parlour, pottery etc. Women have eight shops at a government built shopping centre for selling their produce. <br/><br/>
<strong>Public Health: </strong> As per the Sarpanch (Himanshubhai), maternal and infant mortality rate has been zero per cent for last many years. Primary health centre operates 24x7 with maternity ward, medicine shop and library. ASHA workers visit all homes every month. Expectant mothers receive 100% institutional aids. As Covid-19 response, 10 hand washing stations were installed at places such as milk dairy, bus station etc., and use of masks was made mandatory. <br/><br/>
<strong>Drinking Water: </strong> Piped chlorinated water is supplied to every house through five bore wells. Also there are four hand pumps and a Reverse Osmosis (RO) plant for water purification. All 73 village wells are regularly recharged with rainwater. Pani Samiti (water committee) is functional and retired knowledgeable officers are invited to provide suggestions for effective functioning. <br/><br/>
<strong>Sanitation: </strong> All houses are covered with sanitation units, and the whole village is covered with an underground drainage system for waste and stormwater management. Pay-and-use public toilets are accessible near the bus station. Every household is provided with garbage bins with the service of door to door waste collection, with landfill sites allocated for solid waste management. Whole village is fumigated once every month. <br/><br/>
<strong>Agriculture: </strong> Plantation of fruit and medicinal plants has been done in barren lands. <br/><br/>
<strong>Other: </strong> Punsari has a well managed crematorium for last rites, and mortal remains (asthi) are kept in pots/urns in Gram Panchayat for collective dispersal at Haridwar or other religious sites once in a year. About 80,000 people have visited so far from international and national entities such as bankers from Kenya, professor from Columbia University of USA, Sarpanches from six states from India, southern film superstar Nagarjuna, government officers from multiple states, students of premier schools (including IIMA and IRMA), media teams (including BBC, CNN and  IBN), and various print media. <br/><br/>
<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>


<strong>Leadership: </strong> The Sarpanch (Mr. Himanshu Patel) had started with formation of Gram Panchayat Coordination Committee (GPCC) by involving all the Gram Panchayat members, frontline workers and officers of the government line departments, and the operators of Fair Price Shop and Midday Meal. The frontline workers and others had few challenges in the beginning as they were not used to such coordination, but strong will of the Sarpanch resulted in regularising the GPCC forum. Mr. Himanshu Patel shared that there was resistance in the beginning, but continued efforts made it smooth as the newly recruited or transferred workers and officers felt it easier to be part of the ongoing efforts in a supportive manner. <br/><br/>

Children’s participation was ensured through activities such as village newsletter. Women’s participation was ensured through supporting the SHGs, linking them with the banks and providing them shops to sell their produce. Retired employees or government officers were engaged to provide advice in village development. The well-to-do families were motivated to take up responsibility to support education of the children coming from poor households. Thus human resource mobilisation was the starting point with many of the activities being no-cost or low-cost for the GP, state or central government. <br/><br/>

Through consistent and effective human resource mobilisation, village level social resources (institutions) such as Anganwadi Centres, schools, health centre, fair price shop etc. became more accountable, effective and efficient. In terms of funds, effective mobilisation of the human and social resources for the village development ensured up to 95% successful revenue collection from various GP level taxes such as house tax, water tax, sanitation charges etc. (approximately Rs. 12 lakh per year). Management of natural and material resources for the purposes such as for drinking water supply and effective sanitation resulted in convergence of government programs for the benefits of the children, women and marginalised households. <br/><br/>
The GP has a total 22 full time employees in addition to 47 young women and men employed indirectly. Biometric attendance system in the GP is operational. Toll free telephone and a complaint register ensure speedy grievance redress. Village newsletter with school children providing positive news is an initiative. Four or more Gram Sabhas take place in a year with people’s participation, including for the Gram Panchayat Development Plan (GPDP) process. <br/><br/>
The future plan includes maintaining the current level of successes, which is challenging. Starting motor driving school for women in the village is also a future plan.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 2,
      name: "Jethipura GP",
      desc: "Offers exemplary work on nutrition and education for children",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>19 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Jethipura Gram Panchayat (population as per 2011 Census: total 1242, females 608, males 634) of Idar Block, is located in Sabarkantha district in the state of Gujarat. The village is located at about 15 KM from Idar (nearest town and block headquarters) and 28 KM from Himatnagar, the district headquarters. Children’s population below 6 years includes 122 (female 67, male 55). The population includes 299 persons (135 females, 164 males) from the SC community.  <br/><br/>

Recipient of many awards, Jethipura is equipped with effective and efficient programs ranging from early childhood development to women’s empowerment and to modern infrastructure such as CCTV camera and LCD street lights. It has received hundreds of visitors so far from multiple states of India. The village has a long history of unity among all the people from all the castes, and Gram Panchayat Sarpanch and members have always been selected in Gram Sabha unanimously - never elected. The people here believe that the election creates sub-groups within the village and affects unity. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  The village has total 32 committees, including SMC, Pani Samiti and others. All are active. There are 4 Gram Sabhas organised in a year with presence of more than 50 members in each. The GP has CCTV cameras with 24x7 surveillance. <br/><br/>
<strong>Awards: </strong> The village has received several awards, including a national award amounting to Rs. 18 lakh. The awards include Smart Village Award for the year 2017-18 and Mata Yashoda Award (Rs. 21,000/-) for best Anganwadi centre in the state. <br/><br/>
<strong>Child Nutrition:</strong>  There are two Anganwadi centres in the village having 121 children from 3 to 6 years of age. As per the Sarpanch, Mr. Ahesanbhai Bhatt, there is no malnourished child in the whole village now (19 October 2020). The GP has provided services such as water, electricity and greenery through plants and constructed boundary walls to protect children. The AWCs are also serving 13 pregnant women currently. <br/><br/>
<strong>Education:</strong>  There has been a Primary School in the village since 1929 having 1st to 8th Standards. There are 137 pupils and 7 teachers. English is taught from 3rd Standard. For the 7th and 8th standards, there are smart classes with computers. GP is supporting the school regularly in terms of school building maintenance, ground repairs with cement blocks and greenery. There are 13 members in SMC, and its meeting is regular. <br/><br/>
The village has had a library since pre-independence time, and is linked with the block level library. Membership fee is Rs. 400 per year for reading books, newsletters etc. The library also has a computer and the Internet with online resources with Rs. 2,400 membership fee per year. Therefore, it is running sustainably. The library provides support to the youth with resources to prepare for the competitive exams and employment. <br/><br/>
<strong>Drinking Water:</strong>  The GP has installed a water purification plant and every household has been covered with clean drinking water. <br/><br/>
<strong>Sanitation:</strong>  There was a major challenge of solid waste management, as there was no common land. The GP identified a piece of agricultural land and leased it for solid waste deposit. The village now remains clean. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>
The GP has income from house tax at Rs. 450 per house per year, and it is recovered regularly. Incomes of the GP include Rs. 1.5 lakh tax revenue per year, grants from the Finance Commission and government schemes, Rs. 6.00 lakh for Samras (state government prize for selection of the Sarpanch and Panchayat members with consensus of the whole village, without the need of the election). <br/><br/>


The future plan includes setting up a sound system with speakers in the streets for common announcements and music in the mornings and evenings; constructing entry gates on two sides of the village; and trees plantation on both the sides of the approach road to the village. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 3,
      name: "Daramli GP",
      desc: "Exemplary child-friendly and multiple development activities",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>19 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Daramli Gram Panchayat (population as per 2011 Census: total 1,529, females 730, males 799) of Idar Block is located in Sabarkantha district in the state of Gujarat. The village is located at about 10 KM from Idar (nearest town and the block headquarters) and 23 KM from Himatnagar, the district headquarters. Children’s population below 6 years includes a total of 171 (female 61, male 110). The population includes 55 persons (24 females, 31 males) from the SC community.  <br/><br/>

Recipient of many awards, Daramli is equipped with effective and efficient programs ranging from early childhood development to women’s empowerment and to modern infrastructure such as CCTV camera and public announcement system. It has received hundreds of visitors so far from multiple states of India. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Mrs. Hetalben was elected as Sarpanch for the first time in her first term about 8 years ago. Then in the second term about 4 years ago, she was selected as Sarpanch unanimously by the villagers due to her good work. Under her leadership, 65 to 70 speakers and mikes (announcement system) have been installed in the village. Common announcements are made using the system and religious music is played during the mornings and evenings. The GP has installed CCTV cameras also on the streets. <br/><br/>
<strong>Child Nutrition:</strong>  There are two AWCs in the village. The Anganwadi No. 1 is a model AWC having 28 children of 7 months to 3 years of age and 23 children of 3-6 years of age; and the AWC no. 2 has 18 and 17 children respectively in the two separate age groups. There are 17 pregnant women and 11 lactating women served by both the AWCs. The AWCs also support the adolescent girls. There is only one child malnourished, whose weight at birth was only 1.5 KG, but now after 8 months, he is 5 KG. <br/><br/>
<strong>Education:</strong>  In schools, the GP has promoted children’s banks. The school children deposit some amounts from their pocket money in these banks, and they can withdraw that money for use once a year for educational purposes. <br/><br/>
<strong>Women empowerment:</strong>  There are 22 self-help groups of women that are actively engaged in savings and credit activities. <br/><br/>
<strong>Other:</strong>  Every year, 50 to 100 trees are planted in the village and 45 youth have been engaged to take care of the trees. <br/><br/>
<strong>Vocational training</strong> (e.g. tailoring, beauty parlour, soap making etc.) have been imparted to 251 youth of the village, and 51 of them are now earning incomes.  <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

To begin with as Sarpanch, Mrs. Hetalben had met with all the people of the village (children, women, elderly women, men and others) to understand their needs holistically. She and her husband took up many no cost and low cost activities to serve the people. Such activities include registration of youth who wish to donate blood and can be called when needed; asthi (mortal remains) bank that is collected throughout the year and dispersed once in a year in the Ganges at Haridwar or similar place of religious significance; and trees plantation. The GP has Rs. 770/- house tax per year that includes services of water supply, sanitation and electricity. One time tax of Rs. 5,000/- is levied for a new house construction in the GP. Professional tax from the shops, petrol pump, NGOs and other similar entities is Rs. 200 per month. <br/><br/>

The future plan is to maintain the successes already achieved, and to take up new development activities. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 4,
      name: "Kukma GP",
      desc: "Inspiring ideas and work on Girls’ Education",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>28 September 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Kukma Gram Panchayat (population as per 2011 Census: total 7,216, females 3,483, males 3,733) of Bhuj block is located in Kutch district in the state of Gujarat. The village is located at about 10 KM from Bhuj, the nearest town and the block as well as the district headquarters. Children’s population below 6 years includes a total of 986 (female 458, male 528). The population also includes 1,547 persons (739 females, 808 males) from the SC community and 64 persons (32 females, 32 males) from the ST community.  <br/><br/>

Recipient of Deen Dayal Upadhyay Sashaktikaran Award 2020, Kukma is equipped with effective and efficient programs ranging from early childhood development to women’s empowerment and to modern infrastructure such as CCTV cameras. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Kukma has undergone transformation under the leadership of Mrs. Kankuben Vankar, Sarpanch. There are 27 CCTV cameras, which help to monitor cleanliness and other amenities and services. One public park was renovated for children to play. One more garden work is ongoing. All the committee meetings in the village are regular with discussions and actions taken on the required matters. <br/><br/>
<strong>Child Nutrition:</strong>  As per the Sarpanch, Mrs. Kankuben, there are no malnourished children in the village nowadays (28 September 2020) (though it was one of the challenges earlier), and the GP members visit the AWCs regularly to support the AWCs. <br/><br/>
<strong>Education:</strong>  The GP ensured 100% enrolment and attendance of girls in schools by organising local transport, which was otherwise identified as a challenge. Mrs. Kankuben created an innovation: the girl with the highest education in a ward was recognised by naming a street with her name e.g. Sonali Marg (street). There are 30 streets with such girls’ names in Kukama. <br/><br/>
<strong>Women empowerment:</strong>  On 10 March 2019, women’s day was celebrated by encouraging girls to choose their model women and present it. Eight girls had participated, and model women were given awards. <br/><br/>
<strong>Public Health:</strong>  Immunisation was done by the health department regularly as usual, but the Sarpanch started reviewed the same during the last three years, and provided local support, resulting in 100% children covered since then. <br/><br/>
<strong>Child Protection:</strong>  The GP addressed the harassment issue of the girls by boys. Safety audit was conducted with the support of Kutch Mahila Vikas Sangathan (a local NGO) and a resolution was passed to take strict actions with a copy given to the police department. <br/><br/>
<strong>Drinking water:</strong>  clean drinking water has been ensured by CSR funds. <br/><br/>
<strong>Sanitation:</strong>  Safai Abhiyan (sanitation campaign) was conducted through street plays, and the CCTV cameras are helping to monitor if anyone is littering. As a result, the village is clean. <br/><br/>
<strong>Other:</strong>  Liquor use was also a challenge in the village. The GP passed a resolution to impose a fine of Rs. 2,500 for the person drinking or making liquor, and Rs. 1,500 reward for the person providing information. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

There are 10 Anganwadi Centres (AWCs) in the village. In 2018, there were 6 Severely Acute Malnourished (SAM) children in the village. The well-to-do families were encouraged, who adopted (i.e. informally took responsibility for) these children, and provided support until the children became normal. The Sarpanch and the GP ward members are visiting the AWCs regularly and conducting monitoring. Kukma is a large village. As the girls from far away wards (e.g. Ashapura and Avadh colonies) had to walk 2.5 KM to reach school, they used to drop-out. GP arranged local transport with 65% cost paid  by GP and 35% by parents. This ensured 100% enrolment and attendance of girls in schools. The girls continuing education were encouraged by naming the streets on their names. <br/><br/>

Certain days, for example children’s day on 14 November, are celebrated in the village so as to create enthusiasm and keep up the momentum of good work. Other activities to keep up the momentum include a seminar on menstruation hygiene for girls by calling experts from Welspun (a CSR company). Awareness camps on child labour and child marriage were also organised. Mrs. Kankuben coordinated with the CSR companies and installed RO plant for clean water in school through Agro-Cell and Midday Meal shed through BKT Tyre company.<br/><br/>

The GP has 15 staff members, and about Rs. 75 lakh of income per year from CSR companies and local taxes. The finance commission grant is Rs. 22.96 lakh per year. There are WhatsApp groups of different sectors’ workers e.g. AWCs, Health Centre  and Schools for effective communication. Mrs. Kankuben acknowledged long-term good support from Setu Abhiyan (a local NGO)  in achieving many of the above results. <br/><br/>

The challenges include the students facing difficulties in Maths and English in 7th and 10th Standards. GP is contemplating on addressing it in coming years. <br/><br/>

</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 5,
      name: "Kunariya GP",
      desc: "Visionary good governance for children and all",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>29 September 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Kunariya Gram Panchayat (population as per 2011 Census: total 2,533, females 1,246, males 1,287) of Bhuj Block is located in Kutch district in the state of Gujarat, India. The village is located at about 21 KM from Bhuj, the nearest town and the block and the district headquarters. Children’s population below 6 years includes a total of 594 (female 304, male 290). The population also includes 216 persons (110 females, 106 males) from the SC community and 49 persons (26 females, 23 males) from the ST community.  <br/><br/>

Kunaria planned its own development by analysing its local needs and planning for the same through participatory manner. Adolescents’ Justice Committee was formed, who participate regularly in Gram Sabha and contribute their inputs. <br/><br/>


<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Mr. Sureshbhai is the Sarpanch of Kunariya GP since January 2017. He took initiative to visit 14 good Gram Panchayats of different states for his own learning. In 2017, Sureshbhai conducted a total of 87 events of awareness in different sectors (education, health, water, sanitation etc.), which created active participation of the people. <br/><br/>
Total 7 to 8 Gram Sabhas are organised in a year with nearly 50% women participating. The Gram Panchayat Coordination Committee (GPCC) was formed with 23 members in 2018, and it is working actively. Auxiliary Nurse Midwife (ANMs) were given best practice awards, and such recognition contributes to good mutual cooperation among all members. <br/><br/>
<strong>Education:</strong>  Before Mr. Sureshbhai became Sarpanch, there were 41,000 midday meal attendance by children in a year. By 2018-19, he raised it to 47,000 by conducting monthly reviews with schools. Considering Covid-19 situation, the primary school started a YouTube channel for the teaching-learning process. The link is: <a href="https://www.youtube.com/watch?v=kiGHIyooqac" target="_blank" rel="noreferrer">Link</a> <br/><br/>
<strong>Public Health:</strong>  He focused on children and women’s health to start with. From pregnancy to healthy delivery, proper tracking is ensured by supporting the ANM. He made the Village Health, Sanitation and Nutrition Committee (VHSNC) active, resulting in achievement of 100% immunisation of children. <br/><br/>
<strong>Drinking and Irrigation Water:</strong>  To address long term challenges of drinking and irrigation water, village groundwater mapping was conducted with technical experts’ support, and 6 maps were prepared. Coordination with 26 frontline workers from 39 line departments was ensured in 2018. <br/><br/>
<strong>Agriculture/livelihood:</strong> Considering Covid-19 lockdown and related employment issues, MGNREGA work was started by benefiting 43 households, resulting in assured incomes, especially for women. <br/><br/>
<strong>Other:</strong>  Under MGNREGA, Kunariya GP has been planting 5,000 tree saplings every year since 2017. So far 63,000 trees have been planted and about 57,000 have grown up. <br/><br/>
Kunariya GP also runs a blog at kunariyapanchayat.blogspot.com. It is active in terms of posting information of the events taking place in Kunariya. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

The Gram Panchayat Coordination Committee (GPCC) was formed with 23 members in 2018, and it is working actively. ANMs were given best practice awards, and such recognition contributes to good mutual cooperation among all the committee members. Similarly, the SMC was also made active by monitoring attendance of children and teachers in schools. SMC meetings take place regularly. A local Covid assessment was conducted by forming a team of ASHA Workers, Anganwadi Workers, ANM and youth, resulting in identifying 87 vulnerable households, followed by supporting them in their work. Kunariya has 35 artisan families who make copper bells and 87 women perform embroidery work. Marketing support was provided to them for selling items during the lockdown time. Arrangement with IOC bank was made that a bank representative came to the village once in a week to provide cash, so that the people do not need to go to Bhuj city. <br/><br/>

The challenges as shared by Sureshbhai include lack of comprehensive and latest information of Gram Panchayat related government circulars. There should also be a list of those issues for which the rules making is still pending. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 6,
      name: "Dhrobana GP",
      desc: "A society-wide initiative on child marriage prevention",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>30 September 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Dhrobana Gram Panchayat (population as per 2011 Census: total 8,098, females 3,963, males 4,135) of Khavda block is located in Kutch district in the state of Gujarat. The village is located at about 86 KM from Bhuj. Children’s population below 6 years includes a total of 1,939 (female 947, male 992). The population also includes 252 persons (118 females, 134 males) from the SC community and 4 persons (1 females, 3 males) from the ST community. The village population is comprised mainly of Muslim community. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Child Marriage:</strong>  The major challenge addressed by the Sarpanch includes child marriage. Mr. Siddikbhai is the Sarpanch and he is also a leader of his community living in the surrounding villages. <br/><br/>
Child labour is another challenge that they are facing hard to address. There are few families with just widow women and their children. As the family income is not sufficient, their children work in tea shops, goats grazing and other labour work. <br/><br/>
<strong>Education:</strong>  Mr. Siddikbhai has supported the school children by providing them sports equipment for cricket. <br/><br/>
<strong>Health:</strong>  In Covid-19 emergency, the GP has distributed ration kits, soaps and sanitisers as well as ensured social distancing at the fair price shop of the village. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>
A marriage was taking place in the village during early 2020. The bride was ready with the bridal suit. At such a critical time, a neighbour informed the Sarpanch that the bride was below 18 years of age. In urgent consultation with the District Child Protection Officer, the marriage was stopped. Later on, all the villagers were informed to stop child marriage practice. <br/><br/>
Mr. Siddikbhai also took up the same issue in their broader community (involving multiple villages) and guided the people to stop the child marriage practice.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 7,
      name: "Soyla GP",
      desc: "School Management Committee’s persistent efforts ensure children passing 12th Standard for the first time in the village history",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>30 September 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Soyla Gram Panchayat (population as per 2011 Census: total 452, females 220, males 232) of Khavda block is located in Kutch district in the state of Gujarat. The village is located at about 67 KM from Bhuj. Children’s population below 6 years includes a total of 70 (female 36, male 34). The village is largely populated by Muslim community. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
Being a remote and small village in a dry vast land area, Soyla is an economically poor village. The primary school has 1-8 standards. There are a total of 73 students, including 33 girls. Addition of the 8th standard was the achievement of the SMC, as a result of its members applying for and persuading the district authorities.  For the 9th standard, the students have to go to Khari village, 3 KM away, and for 11 and 12 standards, to Khavda, the Taluka place, 11 KM away. <br/><br/>
Due to the above challenge of distance, the children used to drop out after 8th or 9th standard, and none ever passed 12th standard. The SMC arranged for local transport for the school girls and boys. Due to the SMC’s efforts, the children passed 12th standard last year for the first time. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

Mr. Ramzanbhai, Elected Representative of the Gram Panchayat and member of the SMC, regularly visits the school, checks attendance of the children, and attends all the SMC meetings. SMC decisions are coordinated with the GP for support. In the SMC meetings, important decisions related to drinking water (for example, RO plant and piped water) were made and effectively implemented. Setu Abhiyan has been providing support in the education sector in this village since 2008 by awareness generation activities through street plays and community meetings. Setu Abhiyan is also supporting the GPDP process through facilitating Mahila Sabha and Gram Sabha. Soyla GP has 8 wards with 150 households, and Ward Sabhas are also organised for GPDP. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 8,
      name: "Amardi GP",
      desc: "Sarpanch’s efforts results in improved Anganwadi and School services for girls and boys",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>02 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Amardi Gram Panchayat (population as per 2011 Census: total 1,213, females 639, males 574) of Bhachau block is located in Kutch district in the state of Gujarat, India. The village is located at about 20 KM from Bhachau, the nearest town and the block headquarters, and 62 KM from Bhuj (district headquarters). Children’s population below 6 years includes a total of 142 (female 67, male 75). The population also includes 183 persons (93 females, 90 males) from the SC community and 6 persons (3 females, 3 males) from the ST community. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Amardi has undergone transformation under the leadership of Mrs. Meenaben Vaghela, who is the Sarpanch. She and her husband (retired employee of the forest department) are very active in village development. They have installed 16 CCTV cameras in the village streets in 2019 with a cost of Rs. 2.5 lakh. It helps to monitor the village streets in terms of cleanliness. <br/><br/>
<strong>Child Nutrition:</strong>  As per Mrs. Meenaben, Sarpanch, there are no malnourished children in the village nowadays (02 October 2020). The Sarpanch monitors the AWC functioning regularly and provides support as required. <br/><br/>
<strong>Education:</strong>  In the education sector, they started online tuitions during school closure due to Covid-19 challenge, organised Bal Sabha, and addressed the issues of child protection and education raised by the children. <br/><br/>
<strong>Women empowerment:</strong>  The GP identified 9 widow women and supported them in applications for government scheme benefits. <br/><br/>
<strong>Sanitation:</strong>  The GP has constructed separate toilets for girls and boys in school at a cost of Rs. 1.90 lakh. Clean piped water has also been provided. The GP installed a waste water drainage system in the village. <br/><br/>
The GP carried out tree plantation and arranged for regular watering. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

Mrs. Meenaben Vaghela and her husband, Mr. Manubhai Vaghela, actively worked to improve the situation of the village. UNNATI, an Ahmedabad based NGO with field office in Bhachau, has been providing support to the Amardi Gram Panchayat since 2017. <br/><br/>
In Anganwadi centre, they did not have an Anganwadi Worker, and the centre was run only by Anganwadi Helper providing food, but with no pre-school education activities. As an immediate step, they arranged a temporary Anganwadi Worker at a cost of Rs. 1,500 per month. Then the Panchayat members actively liaised with the block and district levels, and ensured appointment of a regular Anganwadi Worker. <br/><br/>
In school, Bal Sabha was organised and children’s committees were formed for supervision of midday meal, water and sanitation facilities and children’s regular attendance. The children’s committees presented the issues in the GP. The issues included a loosely hanging electric pole near the school (posing risk), repairs required in classrooms and vacancy of a teacher. These issues were addressed by the GP. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 9,
      name: "Khokhra Umar GP",
      desc: "A tribal village Sarpanch enables students to go beyond 10th Standard education",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>21 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Khokhra Umar Gram Panchayat (population as per 2011 Census: total 1,810, females 901, males 909) of Dediapada block is located in Narmada district in the state of Gujarat. The village is located at about 3 KM from Dediapada, the nearest town and the block headquarters and 48 KM from Rajpipla (district headquarters). Children’s population below 6 years includes a total of 224 (female 102, male 122). The village is populated mainly by tribal people, and others include only 34 persons (9 females, 25 males). <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Mr. Dineshbhai has been the Sarpanch since 2015. His motivation is to do such work in the GP that the people always remember him. GPDP is prepared every year. Gram Sabha has a presence of 60 to 70 people, and at least 2 Gram Sabhas are held in a year. There are many committees, including PESA committees, but not active. <br/><br/>
<strong>Child Nutrition:</strong> There are 4 to 5 malnourished children in the GP. The village has 3 Anganwadi Centres. Earlier the focus of the AWCs was only meals. With the supportive monitoring of the Sarpanch and Ward members, now the AWC is also providing services such as early childhood education and playing. <br/><br/>
<strong>Education:</strong>  The GP has 1 Primary School with 1-8 Standards, and there are also 2 Ashram Shalas with 9 to 10 Standards. There are a total of 13 teachers and 285 students in all these institutions. The children in 10th standard used to fail in exams, followed by dropping out, which indicated poor quality of school education. So the Sarpanch introduced tuition classes in Science, Maths and English subjects for the children in the 9th standard. As a result, all the children are now passing in 10th standard, and enrolling in 11th standard. <br/><br/>
Mr. Dineshbhai also set up a library in his village for all the children in 2015. It was supported by AKRSP by providing funds for computers and electricity. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>
Mr. Dineshbhai is enthusiastic and has a vision of improving education in his village. AKRSP has also been supporting the Gram Panchayat in preparation and implementation of GPDP. The GP receives Rs. 30 lakh grant from the Finance Commission per year. MGNREGA grant is Rs. 60 lakh. There are also other state and central government schemes with grants. The GP has an income of Rs. 1.5 lakh from house and sanitation tax. The GP human resources include Talati (revenue officer and village secretary), Peon and VCE. <br/><br/>
Child marriage is a challenge, and the GP is making efforts to address it. In future, the GP aims to do more work in the education sector.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 10,
      name: "Kundiamba",
      desc: "A Tribal Gram Panchayat’s struggle and success in child nutrition",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>22 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Kundiamba Gram Panchayat (population as per 2011 Census: total 1,583, females 794, males 789) of Dediapada block is located in Narmada district in the state of Gujarat, India. The village is located at about 13 KM from Dediapada, the nearest town and the block headquarters, and 58 KM from Rajpipla (district headquarters). Children’s population below 6 years includes a total of 246 (female 110, male 136). The village is populated mainly by the tribal people, and others include only 27 persons (16 females, 11 males). <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>
<strong>Governance:</strong>  Mr. Maganbhai, Sarpanch, has undertaken various work such as roads, drinking water supply, land levelling, wells, soak pits near hand pumps for water recharge, kitchen garden and child nutrition. <br/><br/>
<strong>Child Nutrition:</strong>  There are 3 AWCs in the village. There are 3 to 4 children in Severely Acute Malnourished (SAM) and 10 to 12 children in Moderately Acute Malnourished (MAM) categories in the village currently, and it is a similar situation every year. To address the same, Mr. Maganbhai and the GP members fought battles starting from the block level to the state level, and finally won it, as the state government allowed serving eggs in the meal menu (more details covered in process section below). <br/><br/>
<strong>Education:</strong>  Mr. Maganbhai also supported the village primary school by developing a sports ground for children. Library has also been set up with AKRSP’s support. <br/><br/>
<strong>Women empowerment:</strong>  There are 10 Sakhi Mandals in the village under Mission Mangalam and 3 of them are active. <br/><br/>
<strong>Public Health:</strong>  In Covid-19 situation, the GP took actions such as fumigation and restricted entry into the village for the outsiders. There is no positive Covid case in the GP so far.  <br/><br/>
<strong>Other:</strong>  Under MGNREGA, various works have been taken up such as metal road, ponds deepening or renovation, compost pit, cattle shed etc. Rs. 60 to 70 lakh were spent in such works. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

Usually 3 Gram Sabhas take place in a year, and 50 to 100 people participate in each. Mr. Maganbhai, along with other Panchayat members, thought that the children’s malnourishment can be addressed by providing eggs as meal in the AWC. (It may be noted that, in this tribal area, the people eat non-vegetarian food and vegetables are scarce due to lack of irrigation facilities). The Sarpanch proposed to the ICDS department at the block level to include eggs in the AWC meal menu. However, the decision could not be reached at the block and the district levels. The Sarpanch then escalated it to the state level. It took a few months before the state government delivered the guidance that the local communities may take up a suitable decision based on the local culture. It was a win for the GP in the beginning of 2020. The AWC started serving eggs in early 2020, but then there was a lockdown due to Covid-19, and the AWCs were closed. Therefore, the implementation was disrupted and results were not available to measure children’s growth.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 11,
      name: "Dumalpur school",
      desc: "Offers a practical example of Bal Sansand",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>22 October 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Dumalpur, a village under Dholakuva Gram Panchayat (population as per 2011 Census: total 310, females 152, males 158) of Jhagadia Block is located in Bharuch district in the state of Gujarat. The village is located at about 11 KM from Jhagadia, the nearest town and the block headquarters, and 27 KM from Bharuch (district headquarters). Children’s population below 6 years includes a total of 45 (female 26, male 19). The village is populated mainly by tribal people. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Bal Sansad:</strong>  Bal Sansad (children’s parliament) is the term used for formation of school children’s parliament through democratic election process annually. The process is to be conducted by the Head Teacher along with other school teachers. Purpose of the Bal Sansad process is to introduce the democratic process to the children, promote democratic leadership skills among them, and also promote discipline of carrying out the responsibilities assigned to them. <br/><br/>
Similar to the national parliament, the student getting the most number of votes is declared as Prime Minister (or President), followed by announcements of other Ministers depending upon their vote counts. The ministers, accordingly to their interest, may be allocated various departments such as health, education, sanitation, water etc. Sample guidelines in Hindi and Gujarati for organising Bal Sansad are accessible at the web links, respectively, as follows:&nbsp;
<a href="http://sujal-swachhsangraha.gov.in/sites/default/files/Unicef_Bal_sansad_book_0.pdf" target="_blank" rel="noreferrer">Unicef Bal Sansad Book </a>&nbsp;and&nbsp;
<a href="http://sujal-swachhsangraha.gov.in/sites/default/files/2015_WiS_Bal%20Sansad%20Guideline_Guidelines_Gujarati_Gujarat.pdf" target="_blank" rel="noreferrer">2015 WiS Bal Sansad Guideline Guidelines Gujarati Gujarat</a>. <br/><br/>

It is important to note that the Bal Sansad’s role, as given in the above guidelines, has been envisaged as limited to the school management, and has no role in Gram Panchayat or local governance. Furthermore, Bal Sansad does not represent all the children of the village, especially those who are school drop out (especially adolescent girls), child labour, and children studying outside the GP if the GP has schools only up to Standard 8. Therefore, there is a need for Bal Panchayat to play the role in GP and GPDP, and if Bal Sansad exists in the GP, the Bal Sansad can be merged with Bal Panchayat. <br/><br/>

<Typography variant="h3" align="center">Bal Sansad in Dhumalpur (Gujarat) - Example of Democratic Process to Link With
Gram Panchayat and GPDP</Typography> <br/><br/>

Dumalpur is a tribal village in Jhagadia block of Bharuch district in Gujarat. It has a primary school with Standards 1 to 8 in the village. Since 2016, when Mr. Mukeshbhai Kalidasbhai Vankar became the Head Teacher, he initiated the Bal Sansad concept. There is also a Government of Gujarat guideline (but not mandatory) for Bal Sansad. In Dumalpur primary school, Bal Sansad election is held every year during the month of June or July (as the new academic year starts from June). The students are encouraged to participate in the election process by registering their names for candidacy. The students in Standard 8 are usually more interested in the election and positions. <br/><br/>
Elections are organised in the same manner as for national/state elections. The candidates are allowed to choose their symbols and conduct campaigns for votes. <br/><br/>
On the day of voting, election booth is created with children playing the role of polling officers and guards. The students cast their votes through stamping the sign of their chosen candidate.
Votes counting is done in transparent manner. <br/><br/>
The candidate with the highest votes becomes the President, followed by Vice-President and Ministers for departments such as Sanitation, Health, Midday Meal and others. The President plays the leadership role. For example, Master Rakesh Mangabhai Vasava, Standard 8 student, had become President and he played excellent role of motivating other ministers to do their work effectively. <br/><br/>
Thus the children are exposed to the democratic process of election. They get the opportunities to develop their democratic leadership and follower-ship skills right from the school age. <br/><br/>
The Dumalpur primary school links this Bal Sasad with the School Management Committee but not the GPDP yet, which is an opportunity in coming years. Other schools may also follow the suit, and the Sarpanches can encourage the schools. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 601,
      name: "Pachisgam Gram Panchayat",
      desc: "National child friendly award winner Gram Panchayat",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>21 December 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Pachisgam Gram Panchayat: population as per 2011 Census: total 1,541, females 753, males 788. Pachisgam is located in Sankheda block of Chhota Udepur district (earlier it was part of Vadodara district) in the state of Gujarat. The village is located at about 18 KM from Sankheda, the nearest town and the block headquarters, and 54 KM from Chhota Udepur (the district headquarters). Children’s population below 6 years includes a total of 208 (female 91, male 117). The village is populated largely by tribal people (population 678, female 338, male 340), and others include 8 persons (4 females, 4 males) from SC and the remaining from general category. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Governance:</strong>  Mr. Arvindbhai Rathava has been the Sarpanch for the last three terms. Ms. Rinkuben Chaudhari is the Talati, and Mr. Dipakbhai Rathwa is the Village Computer Entrepreneur (VCE). The Sarpanch focused on children’s nutrition, health and sanitation in AWC and school. <br/><br/>

<strong>Award received:</strong>  The Pachisgam GP received the national award for the Best Child Friendly Gram Panchayat of Gujarat in 2020 (assessment year 2018-19). The award includes Rs. 5 lakh cash and a certificate. Nomination for the award was suggested by the block and district panchayat office, and the award was given after the visit from the central government’s monitoring team. <br/><br/>

<strong>Child Nutrition:</strong>  To improve nutrition status, the village has a good practice of giving a coconut and natural sugar to the first time pregnant woman. The AWW holds regular meeting of the pregnant women and lactating mothers to raise awareness of nutritional food intake. The AWC is under construction, as the old building was damaged.  There are three children in red zone i.e. SAM children, who are 6 months to 3 years of age. They are referred to nutrition rehabilitation centre at the block level. <br/><br/>
<strong>Education:</strong>  The primary school (1st to 5th Standard) has 45 children (18 girls and 27 boys) with 2 teachers. The school has formed Bal Sansad (not through election, but through informal meeting and selection process by the teachers). The Bal Sansad has various teams to monitor sanitation, drinking water, construction activities and midday meal arrangements. <br/><br/>
Public Health: Ms. Sumitraben Rathwa, AWW and Ms. Ushaben Rathwa, ASHA Worker work together to address the public health issues, especially taking care of pregnant women and new born children (as mentioned above). <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

The Sarpanch enjoys popularity of the villagers, as he has been selected unanimously, called Samras, by the villagers for the last three terms without election. The Samras process is rewarded by the state government through a cash prize of Rs. 6 lakh. <br/><br/>
Pachisgam Gram Panchayat, among total three, was recommended by the District Panchayat for nomination for the child-friendly award. The central government team had then conducted verification visit, and checked the AWCs, school, Gram Panchayat office, sanitation units within these institutions and the village as a whole. Based on that team’s recommendation, Pachisgam was selected as the best child friendly Gram Panchayat of Gujarat for the assessment year 2018-19.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 602,
      name: "Sisodara Gram Panchayat",
      desc: "National child friendly award winner GP",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>25 December 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Sisodara Gram Panchayat: population as per 2011 Census: total 1,600, females 797, males 803. Sisodara is located in Jalalpore Block of Navsari district in the state of Gujarat. The village is located at about 4 KM from Navsari (district headquarters), and 10 KM from Jalalpore, the block headquarters. Children’s population below 6 years includes a total of 171 (female 74, male 97). The village is populated largely by tribal people (population 1,057, female 512, male 545), and others include 131 persons (67 females, 64 males) from SC and the remaining from general category. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Governance:</strong>  Mr. Harshabhai Desai (also known as Rajubhai) is the Sarpanch and Mr. Bipinbhai Chauhan is the Talati. Mr. Harshadbhai has been Sarpanch of Sisodara GP since 2017. He is also a social worker from Bharatiya Janata Party since long time. Ms. Pinal Patel, Head Teacher of Primary School is active in education sector, and played key role in winning award for the GP. <br/><br/>

<strong>Award received:</strong>  Sisodra GP received the national award for the Best Child Friendly Gram Panchayat of Gujarat in 2019 (assessment year 2017-18). The award includes Rs. 5 lakh cash and a certificate, given in a function held in New Delhi. <br/><br/>

<strong>Child Nutrition:</strong>  Mr. Harshadbhai ensured that all the children from 3 to 6 years of age attend the AWCs and benefit from its services. There are 9 AWCs in the village. <br/><br/>

<strong>Education:</strong>  There are 4 Primary Schools and one High School with 1st to 12th Standards. Children’s and teachers’ attendance in schools, quality of teaching learning process and promotion of sports are some of the salient features as highlighted by Mr. Harshadbhai. <br/><br/>

One of the primary schools was 125 years old, and required renovation. Mr. Harshadbhai took initiative and supported the Head Teacher by visiting the rich as well as common households, and raising Rs. 1.20 crore for reconstruction.  The reconstruction work has been completed. Being an industrial area, the village has a few industries as well, and Mr. Harshadbhai raised funds from them also for different facilities (for example, for computers) in the village schools. He encouraged and supported the school in sports for children, resulting in the students winning state level competition. <br/><br/>
<strong>Public Health:</strong>  He also ensured that the ASHA workers go to every household and ensure that all the women receive required health services. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

Mr. Harshadbhai followed the process of influencing multiple stakeholders: the villagers to become aware about the importance of eduction, rich people and the industries to donate for school, school teachers to feel motivated by moral and financial support, and children and adolescent girls to become aware, for example, through household visits and awareness camps on menstruation hygiene and accessibility of sanitary pads. He ensured that the schools take more care of the children than the families. He organised meetings with the tribal communities living in the village and raised awareness to send children to schools regularly. <br/><br/>

Due to the above processes and achievements, the district government recommended the Sisodara GP for nomination for the child friendly national award. The award was decided after the verification visit by a central government team, and given in 2019. The process of nomination, scrutiny and selection was similar to Pachisgam village as mentioned earlier.
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 14,
      name: "Amba Mahuda GP",
      desc: "100% immunisation through an RTI battle",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>20 December 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Amba Mahuda Gram Panchayat: population as per 2011 Census: total 1,422, females 664, males 758. Amba Mahuda is located in Poshina block of Sabarkantha district in the state of Gujarat. The village is located at about 5 KM from Poshina, the nearest town and block headquarters, and 97 KM from Himatnagar (district headquarters). Children’s population below 6 years includes a total of 369 (female 174, male 195). The village is populated largely by tribal people, and others include only 4 persons (1 females, 3 males) from SC and remaining few from other communities. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Governance:</strong>  Mr. Shankarbhai Parmar is the Sarpanch and Mr. Modabhai Dabhi is the Deputy Sarpanch since 2016. Mr. Gulabbhai Parmar is a member of the Gram Vikas Samiti (GVS), a committee formed and strengthened by UNNATI (an Ahmedabad-based NGO with field office in the local area). <br/><br/>

<strong>Education:</strong>  Mr. Modabhai helped to activate the SMC by visiting the school and checking the arrangements. He observed that the teachers were busy in their mobile phones and midday meal lacked quality. The SMC then started checking teachers’ presence regularly, resulting in overall improvements in school education. <br/><br/>

<strong>Public Health:</strong>  Mr. Gulabbhai Parmar  ensured 100% immunisation of the children in his village through fighting a Right to Information battle with the local health authorities (more information follows in the process section below). Total 22 women received benefits of delivery as per the government scheme in his village. As the PHC became active, more than 100 women from other villages also received such benefit. <br/><br/>

<strong>Other:</strong>  Furthermore, Mr. Gulabbhai  helped the elderly people by linking them with the old age and widow pension scheme of the government, resulting in continued monthly benefits to about 60 households. <br/><br/>

Mr. Modabhai and Gulabbhai also helped the households to access ration from the PDS shop by updating the ration cards. As Mr. Gulabbhai demonstrated such leadership, he became well known at the block level. Using this influence, he asked the Block Development Officer (BDO) to check and revise the BPL list prepared by Talati and Sarpanch wrongly. As the BDO ordered the Talati and Gram Sevak to re-check, house-to-house survey was undertaken with the GVS members’ support, and the list was revised with genuine 118 BPL households. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

UNNATI’s interventions of awareness raising for improved governance at the Gram Panchayat level is the key to the successes mentioned above that relate to people’s awareness to demand the services as per their entitlements. The successes also relate to identifying and promoting local leadership in improving local governance. <br/><br/>

Mr. Modabhai learned from UNNATI’s initiatives of community awareness, formation and strengthening of GVS, and training of the GVS leaders. Then he visited all the households to spread awareness about the government schemes. As a result, he could help many elderly people and widow women to access government pension schemes and the marginalised families to access full ration (food grain) through Public Distribution System (PDS). Earlier, the people were not getting the full ration quota due to lack of awareness and confidence to demand from the PDS shop owner. Other development work strengthened by him include enabling the SMC to be active in monitoring the effectiveness of school education, construction of bore well for drinking water and RCC road through 14th Finance Commission grant of Rs. 29 lakh. <br/><br/>

Mr. Gulabbhai has been a GVS member since 2016. He became aware about the government schemes and people’s entitlements through UNNATI’s programs (as mentioned above). He noticed that the vaccines meant for the children were not being administered as planned, but were thrown away by the nurses, so as to avoid travelling to remote places and visiting households that are scattered across hilly terrain. The village ASHA worker, one of his relatives, was also involved in this malpractice. <br/><br/>

Mr. Gulabbhai used the Right to Information (RTI) act, wrote to the Primary Health Centre (PHC) authority and demanded information with regard to the number of children and immunisation work. The PHC refused, through a letter, to provide the information. He then appealed to the district level for the same information, citing the refusal letter from the PHC. The District authority organised a hearing, and ordered the PHC to provide the information within the timeframe. As a result, the malpractice stopped, not only in his own village, but in all the villages of the Poshina block, benefitting all the children of the block. <br/><br/>

Mr. Gulabbhai said that he wants to serve the people through such initiatives, and does not want to become Sarpanch or take up any formal position. His leadership is thus unique as he is serving without self-interest. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 15,
      name: "Rahad GP",
      desc: "A shining example of good governance for quality primary education",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>22 December 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Rahad Gram Panchayat: population as per 2011 Census: total 931, females 458, males 473. Rahad is located in Vagra block of Bharuch district in the state of Gujarat. The village is located at about 9 KM from Vagra, the nearest town and block headquarters, and 24 KM from Bharuch (district headquarters). Children’s population below 6 years includes a total of 110 (female 48, male 62). The village is populated largely by tribal people, and others include 31 persons (15 females, 16 males) from SC and remaining from other communities. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Governance:</strong>  Mrs. Neemaben Patel has been the Sarpanch since 2016; Mrs. Rashmikaben and Mrs. Gayatriben, both are teachers in the primary school of the village; Mrs. Madhuben Vasava is the SMC head; and  Mr. Kaushikbhai Patel and Mr. Devendrabhai Patel are both informal village leaders. The village has unity and harmony across the school teachers, tribal community and the well-off community (Patels). Gram Vikas Trust (GVT), a local NGO, with technical and funding support from Reach to Teach (an international education charity), has demonstrated Community Owned Attendance Program (COAP) initiative that promotes 100% attendance of pupils in the school, which is a prerequisite for delivery of quality education. <br/><br/>

<strong>Education:</strong>  There is a primary school with 1st to 8th Standards with 111 children. The school has achieved 100% enrolment and almost 100% regular attendance of all the eligible children. In Covid-19 situation, the teachers are taking the classes for individual students as per the students’ level. Though online education is delivered, the children and their families require reminders and those who do not have TV set or smart phones are helped with by other households with such equipment. The COAP members help the teachers in this regard. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

In Rahad GP, the school attendance of children from the tribal community was irregular, which affected school education outcomes. The COAP initiative addressed the same. The COAP is comprised of the members from the SMC, Gram Panchayat and other informal village leaders identified as having passion in promoting education for children. The 11 COAP members include Sarpanch, 5 SMC members and 5 informal village leaders. The village has been divided into 11 different parts (Faliyas), with every COAP member taking responsibility of one Faliya. <br/><br/>

Each COAP member ensures that every child from her or his Faliya is at the school each and every day. If a child is seen roaming in the village during the school hours or if the teacher reports a specific child as absent, the respective COAP member takes immediate action. <br/><br/>

The COAP members inquire in-depth and resolve the issue. For example, a child was absent for couple of days. The COAP member inquired and found out that, due to a family conflict, the child’s mother had gone away to her maternal village and taken the child along with her. The COAP member intervened and resolved the family dispute, resulting in returning of the child and the mother back to the village and regular attendance of the child at school again. Similarly, the COAP members have resolved each and every issue facing the children in terms of primary education. GVT and Reach to Teach have compiled a list of dozens of such issues and the solutions found by the COAP members in many GPs of Vagra and Jhagadia blocks of Bharuch district. <br/><br/>
</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
    {
      id: 16,
      name: "Govali GP",
      desc: "Community’s ownership in strengthening primary education",
      avatar: avatar,
      gpImgLaptop: gpImgL,
      gpImgMobile: gpImgM,
      content: <React.Fragment>23 December 2020<br/><br/>

<Typography variant="h3">Introduction</Typography> <br/><br/>

Govali Gram Panchayat: population as per 2011 Census: total 4,381, females 2,084, males 2,297. Govali is located in Jhagadia Block of Bharuch district in the state of Gujarat. The village is located at about 10 KM from Jhagadia, the nearest town and block headquarters, and 14 KM from Bharuch (district headquarters). Children’s population below 6 years includes a total of 444 (female 206, male 238). The village is populated with nearly one-third of tribal people, and others include 225 persons (103 females, 122 males) from SC and the remaining from other communities. <br/><br/>

<Typography variant="h3">Major Achievements</Typography> <br/><br/>

<strong>Governance:</strong>  Mrs. Sumanben Vasava has been the Sarpanch since 2016. Mr. Purushottambhai Patel is an SMC member, Ms. Jalpa Vasava is the GVT Community Facilitator and Mrs. Hansaben is a COAP member. The COAP members contribute greatly to the local governance for improvement in primary school education (as described in the previous case story). <br/><br/>

<strong>Education:</strong>  There is a primary school with 1st to 8th Standards. Similar to Rahad (previous example), the Govali school has also achieved 100% enrolment of all the eligible children in school, and almost 100% attendance of all the children. <br/><br/>

Regular attendance of the children at school was a major challenge, as the parents go to daily wage labour work, and the children are left behind to care for themselves or by older siblings or by the elderly people. This affected school education outcomes. <br/><br/>

There are 11 members in COAP, which includes 2 SMC members as well. The COAP members ensure that the children are in school during the school hours, and not in homes or roaming around in the streets. Due to Covid-19, GVT conducted a survey to identify the households with and without TV sets, smart phones and Internet access. The households without such facilities who have school going children were helped by the COAP members and the school teachers in terms of attending regular classes by linking them with those households having the required equipment. <br/><br/>

The GP helped the school with RO machine for clean drinking water and iron sheet shed for the midday meal arrangement. In one instance, the COAP and GP members worked together to address a school reconstruction issue. The reconstruction work was awarded directly by the state government to a contractor. The contractor - thinking that the tribal village will not be able to do anything - did not take it seriously and he left the work half way. However, the GP and the COAP together followed up through their Member of Legislative Assembly and ensured the completion of the school reconstruction work. <br/><br/>

<Typography variant="h3">Process: How did the Gram Panchayat achieve successes? </Typography> <br/><br/>

GVT’s strategy of Community Facilitator (CF) and COAP are playing important role in ensuring quality education for the children attending the village primary school in a tribal village of Govali.  (Unlike in Rahad, Govali’s COAP is supported by an additional member called CF, as the village is large and remote). While CF is a temporary arrangement, COAP is the community-based long-term mechanism that ensures enrolment and attendance of all the eligible children in school.

</React.Fragment>,
      link: "/GP-Success-Stories/GP-Details",
    },
  ];

  return gpArray;
}
