
export default function TopGPData() {

  const gpArray = [
    {
      year: 20,
      data: [
        {
          id: 601,
          name: "પચીસગામ, છોટા ઉદેપુર જિલ્લો",
          desc: "રાષ્ટ્રીય બાળ મૈત્રીપૂર્ણ એવોર્ડ વિજેતા ગ્રામ પંચાયત",
          avatar: require('../../../assets/gp-success/child.png'),
          link: "/gu/GP-Success-Stories/GP-Details",
        },
      ]
    },
    {
      year: 19,
      data: [
        {
          id: 602,
          name: "સિસોદરા, નવસારી જિલ્લો",
          desc: "રાષ્ટ્રીય બાળ મૈત્રીપૂર્ણ એવોર્ડ વિજેતા ગ્રામ પંચાયત",
          avatar: require('../../../assets/gp-success/child.png'),
          link: "/gu/GP-Success-Stories/GP-Details",
        },
      ]
    },
  ];

  return gpArray;
}
