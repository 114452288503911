import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonArrow from './ui/ButtonArrow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
// import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import villageImg_md from '../../assets/village2_md.jpg';
import villageImg_sm from '../../assets/village2_sm.jpg';
// import doorImg from '../assets/door.png';
// import slantLine from '../assets/SlantLine.svg';
// import doorImg from '../assets/door.jpg';
import revolutionBackground from '../../assets/repeatingBackground.svg';
import avatar from '../../assets/avatar.png';
import contactus from '../../assets/contactus.jpg';
import contactusSM from '../../assets/contactusSM2.jpg';


const useStyles = makeStyles(theme => ({
  coverImage: {
    maxWidth: "50em",
    [theme.breakpoints.down("md")]: {
      maxWidth: "35em",
      maxHeight: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      maxHeight: "300px",
      width: "100%",
    }
  },
  heroTextContainer: {
    marginLeft: "10em",
    [theme.breakpoints.down("md")]: {
      margin: "3em 2em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "3em 1.5em"
    }
  },
  buttonContainer: {
    marginTop: "1em"
  },
  learnButtonHero: {
    ...theme.typography.learnButton,
    fontSize: "0.9rem",
    height: 45,
    width: 180
  },
  learnButton: {
    ...theme.typography.learnButton,
    fontSize: "0.7rem",
    height: 35,
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    }
  },
  mainContainer: {
    marginTop: 0,
  },
  specialText: {
    fontFamily: "Pacifico",
    color: theme.palette.common.orange
  },
  subtitle: {
    marginBottom: "1em"
  },
  icon: {
    marginLeft: "2em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  },
  serviceContainer: {
    marginTop: "12em",
    [theme.breakpoints.down("sm")]: {
      padding: 25
    }
  },
  revolutionBackground: {
    backgroundImage: `url(${revolutionBackground})`,
    backgroundPostion: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%"
  },
  revolutionCard: {
    position: "absolute",
    boxShadow: theme.shadows[10],
    borderRadius: 15,
    padding: "10em",
    [theme.breakpoints.down("sm")]: {
      padding: "8em 0",
      width: "100%",
      borderRadius: 0
    }
  },
  sectionContainer: {
    padding: "3em 0",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3em 1em",
    }
  },
  cardsContainer: {
    padding: "2em",
  },
  root: {
    padding: "1em 0.5em",
    width: "300px",
    marginRight: "50px",
    paddingBottom: 0,
    borderColor: theme.palette.grey['200'],
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "5px 5px",
    },
    "&:hover": {
      boxShadow: "0 14px 28px rgba(150, 150, 150, 0.25)"
    },
  },
  media: {
    padding: "20px",
    fontSize: 50,
    borderRadius: 25,
    backgroundColor: theme.palette.common.color2,
    fill: theme.palette.common.color1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 40
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.common.color1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    }
  },
  aboutContainer: {
    padding: "3em 10em",
    backgroundColor: theme.palette.common.greyLight,
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  teamContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  rootTeam: {
    display: 'flex',
  },
  detailsTeam: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentTeam: {
    flex: '1 0 auto',
  },
  coverTeam: {
    width: 100,
  },
  teamDesc: {
    paddingBottom: "20px"
  },
  teamCardRoot: {
    width: "188px",
    margin: "0px 100px",
    padding: "1em 0.5em",
    paddingBottom: "0em",
    borderColor: theme.palette.grey['200'],
    [theme.breakpoints.down("sm")]: {
      margin: "0px 30px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 10px",
    },
    "&:hover": {
      boxShadow: "0 14px 28px rgba(150, 150, 150, 0.25)"
    },
  },
  avatarMedia: {
    height: 150,
    width: 150,
    borderRadius: 20,
    margin: "0px auto"
  },
  contactContainer: {
    padding: "3em 10em",
    backgroundColor: theme.palette.common.greyLight,
    backgroundImage: `url(${contactus})`,
    backgroundPostion: "right",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "600px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
      backgroundImage: `url(${contactusSM})`,
    }
  },
  contactText: {
    fontSize: "1.25rem",
    fontFamily: "Roboto",
    fontWeight: 300,
    color: "#fff",
  },
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const setLinkPath = (e, tab1, tab2) => {
    props.setValue(tab1);
    props.setSelectedIndex(tab2);
  }

  return (
    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item> {/*----------Cover Block-----------*/}
        <Divider light />
        <Grid container justify="center" alignItems="center" direction={ matchesSM ? "column-reverse" : "row"}>
          <Grid md item className={classes.heroTextContainer}>
            <Typography variant="h2" align="left">
              આપનું સ્વાગત છે - <br/> <span style={{color: theme.palette.common.color1}}>આ ગ્રામ પંચાયત જ્ઞાન મંચ પર</span>
            </Typography>
            <Typography variant="subtitle1" align="left">
              સરપંચ | ગ્રામ પંચાયત ચૂંટાયેલા સભ્યો | અન્ય સમિતિઓના સભ્યો અને | નાગરિકો માટે
            </Typography>
            <Grid container justify="flex-start" className={classes.buttonContainer}>
              <Grid item>
                <Button variant="outlined" className={classes.learnButtonHero} component={Link} to="/gu/resources" onClick={(e) => setLinkPath(e, 2, 3)}>
                  <span style={{marginRight: 10}}>વધુ માહિતી માટે</span>
                  <ButtonArrow width={15} height={15} fill={theme.palette.common.color1}/>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid md item container justify={matchesSM ? "center" : "flex-end"}>
            <img alt="village" src={matchesSM ? villageImg_sm : villageImg_md} className={classes.coverImage}/>
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <Grid item className={classes.sectionContainer}> {/*----------Services Block-----------*/}
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h4" align="center">
              આપને ગ્રામ સ્વરાજને લગતી ઘણી ઉપયોગી માહિતિ અહીં મળશે
            </Typography>
          </Grid>
          <Grid item className={classes.cardsContainer}>
            <Grid container direction="row" justify="center">
              <Grid item>{/*----------Services Card 1-----------*/}
                <Card className={classes.root} variant="outlined">
                  <Grid item container direction="column" alignItems="center">
                    <FileCopyRoundedIcon className={classes.media} style={{fill: theme.palette.common.resources, backgroundColor: theme.palette.common.resourcesLight}}/>
                    <CardContent>
                      <Typography align="center" className={classes.title} style={{color: theme.palette.common.resources}}>
                        જ્ઞાન કોષ
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" component={Link} to="/gu/resources" onClick={(e) => setLinkPath(e, 2, 3)} style={{color: "#999"}}>વધુ માહિતી માટે</Button>
                    </CardActions>
                  </Grid>
                </Card>
              </Grid>
              <Grid item>{/*----------Services Card 2-----------*/}
                <Card className={classes.root} variant="outlined">
                  <Grid item container direction="column" alignItems="center">
                    <FormatQuoteRoundedIcon className={classes.media} style={{fill: theme.palette.common.stories, backgroundColor: theme.palette.common.storiesLight}}/>
                    <CardContent>
                      <Typography align="center" className={classes.title} style={{color: theme.palette.common.stories}}>
                        પંચાયત સફળતાની વાતો
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" component={Link} to="/gu/GP-Success-Stories" onClick={(e) => setLinkPath(e, 1, 3)} style={{color: "#999"}}>વધુ માહિતી માટે</Button>
                    </CardActions>
                  </Grid>
                </Card>
              </Grid>
              <Grid item>{/*----------Services Card 3-----------*/}
                <Card className={classes.root} variant="outlined">
                  <Grid item container direction="column" alignItems="center">
                    <ContactSupportRoundedIcon className={classes.media} style={{fill: theme.palette.common.expert, backgroundColor: theme.palette.common.expertLight}}/>
                    <CardContent>
                      <Typography align="center" className={classes.title} style={{color: theme.palette.common.expert}}>
                        પ્રશ્નો અને જવાબો
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" component={Link} to="/gu/faq" onClick={(e) => setLinkPath(e, 3, 0)} style={{color: "#999"}}>વધુ માહિતી માટે</Button>
                    </CardActions>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <Grid item className={classes.aboutContainer}> {/*----------About Block-----------*/}
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid md item>
            <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>અમારા વિશે</span></Typography>
            <br/>
            <Typography variant="body1">
              વિકેન્દ્રિત સ્વ-શાસન, ટૂંકમાં વિશ્વાસ (Visvas), એ ગુજરાતની ગ્રામ પંચાયતો માટેનો એક મંચ (ઓનલાઈન પોર્ટલ) છે.
              આ ઓનલાઈન પોર્ટલનો હેતુ ગ્રામ પંચાયતના આગેવાનો અને વિષય નિષ્ણાતો વચ્ચે જ્ઞાનના આદાનપ્રદાનનો છે. આ "પાર્ટનર 1", પાર્ટનર 2 "અને UNXXXXની ભાગીદારી દ્વારા કરવામાં આવેલી એક પહેલ છે.
            </Typography>
            <br/>
            <Typography variant="body1">
              આ પોર્ટલનો હેતુ ગ્રામ પંચાયત સરપંચો, ચૂંટાયેલા પ્રતિનિધિઓ, વિવિધ સમિતિના સભ્યો અને નાગરિકો માટે ઉપયોગી માહિતીને સરળ રીતે ઉપલબ્ધ બનાવવાનો છે.
              આ સંસાધનોમાં ગ્રામ પંચાયત વિકાસ યોજના (જી.પી.ડી.પી.) માટેની માર્ગદર્શિકા, ગ્રામ પંચાયત કક્ષાની વિવિધ સમિતિઓ, તેમની ભૂમિકાઓ, પંચાયતોને લગતા ગુજરાત સરકારના પરિપત્રો, વગેરેનો સમાવેશ થાય છે.
            </Typography>
            <br/>
            <Typography variant="body1">
              અમે આશા રાખીએ છીએ કે આપને અમારી આ  પહેલ ગમશે અને અહીં ઉપલબ્ધ  જ્ઞાન સંસાધનોથી આપને લાભ થશે.
              આપને કંઈ પણ પ્રશ્ન હોય તો પ્રશ્નો અને જવાબો મેનુ ઓપ્શન ઉપર ક્લિક કરી પૂછી શકો છો. અમે પ્રશ્નોના જવાબ આપવાનો પ્રયત્ન કરીશું.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <Grid item className={classes.teamContainer}> {/*----------Team Block-----------*/}
        <Grid container justify="flex-start" alignItems="flex-start" direction="column">
          <Grid md item className={classes.teamDesc}>
            <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>અમારી ટીમને મળો</span></Typography>
            <br/>
            <Typography variant="body1">

            </Typography>
          </Grid>
          <Grid md container item direction="row" justify="center">
            <Card className={classes.teamCardRoot}>
              <CardMedia
                className={classes.avatarMedia}
                image={avatar}
                title="avatar"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  સભ્ય 1
                </Typography>
                { matchesXS ? '' :
                  <Typography variant="body2" color="textSecondary" component="p">
                    Position | Description
                  </Typography>
                }
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  LinkedIn (Optional)
                </Button>
              </CardActions>
            </Card>
            <Card className={classes.teamCardRoot}>
              <CardMedia
                className={classes.avatarMedia}
                image={avatar}
                title="avatar"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  સભ્ય 2
                </Typography>
                { matchesXS ? '' :
                  <Typography variant="body2" color="textSecondary" component="p">
                    Position | Description
                  </Typography>
                }
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  LinkedIn (Optional)
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Divider light />
      <Grid item className={classes.contactContainer}> {/*----------Contact Us Block-----------*/}
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid md item>
            <Typography variant="h2"><span style={{color: theme.palette.common.color2}}>અમારો સંપર્ક કરો</span></Typography>
            <br/><br/>
            <Grid container direction="row" className={classes.contactText}>
              <Grid item>
                <AccountBoxIcon style={{paddingRight: "10px"}}/>
              </Grid>
              <Grid item>
                નામ
              </Grid>
            </Grid>
            <br/>
            <Grid container direction="row" className={classes.contactText}  wrap="nowrap">
              <Grid item>
                <LocationOnIcon style={{paddingRight: "10px"}}/>
              </Grid>
              <Grid item>
                સરનામું
              </Grid>
            </Grid>
            <br/>
            <Grid container direction="row" className={classes.contactText}>
              <Grid item>
                <EmailIcon style={{paddingRight: "10px"}}/>
              </Grid>
              <Grid item>
                ઇ-મેઇલ
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
