
export default function TopGPData() {

  const gpArray = [
    {
      year: 20,
      data: [
        {
          id: 601,
          name: "Pachisgam Gram Panchayat - year 2020",
          desc: "National child friendly award winner Gram Panchayat",
          avatar: require('../../assets/gp-success/child.png'),
          link: "/GP-Success-Stories/GP-Details",
        },
      ]
    },
    {
      year: 19,
      data: [
        {
          id: 602,
          name: "Sisodara Gram Panchayat - year 2019",
          desc: "National child friendly award winner GP",
          avatar: require('../../assets/gp-success/child.png'),
          link: "/GP-Success-Stories/GP-Details",
        },
      ]
    },
  ];

  return gpArray;
}
