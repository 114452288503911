import React from "react";

export default function FAQuestions() {

  const quesArray = [
    {
      question: "1. What is SMC and how is it formed?",
      answer: "SMC means School Management Committee for the overall management of the school education. The Head Teacher of the primary school may call a Gram Sabha or Parents’ meeting to form the SMC composed of 12 members.The SMC needs to be reconstituted every two years.",
      panel: "panel1",
      ariaControl: "panel1-content",
      id: "panel1-header",
    },
    {
      question: "2. Who can be the SMC members?",
      answer: "Out of total 12 members, 75% parents, including representatives of marginalised community parents proportionate to number of students, should be included in the SMC. Total 50% should be women members in the committee. Other members include: 1 Teacher from school (to be selected by all the teachers); 1 Elected Representative, or 1 Trust or Management member (in case of a private school); 1 Village masson; and 1 Education expert or 1 student as can be selected by the SMC’s parent members.",
      panel: "panel2",
      ariaControl: "panel2-content",
      id: "panel2-header",
    },
    {
      question: "3. What are the responsibilities of the VHSNC?",
      answer: <React.Fragment>
      <h5>VHSNC’s Responsibilities:</h5>
      <ol>
        <li>Identify needs and prepare village level Health, Sanitation and Nutrition Annual Plan and Budget, including monthly and quarterly break up of the activities and budget.</li>
        <li>Monitor the monthly and quarterly activities and budget, and make adjustments as may be necessary. It includes monitoring of access to and delivery of the health centre services.</li>
        <li>Maintain accounting of the Untied Village Fund given to the VHSNC.</li>
        <li>Organising monthly or seasonal campaigns to raise awareness on health, nutrition and sanitation issues, and promote community actions.</li>
        <li>VHSNC members will make the people aware about the health program and services available at the health centres. Awareness can be generated through IECs such as wall painting, street plays, film show, posters and talk show with experts.</li>
        <li>Take preventive actions on malnutrition, anaemia and other sicknesses. Spread awareness on these issues, ensure cleanliness in the village, and support the communities in accessing health and nutrition services.</li>
        <li>ASHA worker, Anganwadi Worker and Auxiliary Nurse Midwife (ANM) may identify the BPL, SC, ST, minority and other disadvantaged families, provide them awareness and help them access the services.</li>
      </ol>
      </React.Fragment>,
      panel: "panel3",
      ariaControl: "panel3-content",
      id: "panel3-header",
    },
    {
      question: "4. What is PESA and its committees?",
      answer: <React.Fragment>
        <p>Under the <strong style={{color: "#01579b"}}>Panchayati Raj Institution (PRI)</strong> system, there is a separate provision for tribal areas, as covered in Panchayat Extension
        to Scheduled Area <strong style={{color: "#01579b"}}>(PESA)</strong> Act 1996, and subsequent laws by the state government.
        Under PESA, there are three committees of the <strong style={{color: "#01579b"}}>Elected Representatives (ERs)</strong>: (a) Peace Committee,
        (2) Resource Planning and Management Committee (RPMC), and <strong style={{color: "#01579b"}}>Vigilance and Monitoring Committee (VMC)</strong>.</p>
      </React.Fragment>,
      panel: "panel4",
      ariaControl: "panel4-content",
      id: "panel4-header",
    },
    {
      question: "5. What is GPDP?",
      answer: "GPDP means Gram Panchayat Development Plan. It is an annual plan for the whole Gram Panchayat covering all the needs of the village, including resources available from local funds as well as state and central government grants. GPDP is prepared annually, and a five year comprehensive Vision Document can also be prepared the Gram Panchayat.",
      panel: "panel5",
      ariaControl: "panel5-content",
      id: "panel5-header",
    },
    {
      question: "6. What is Bal Panchayat?",
      answer: <React.Fragment>
        <strong><em>Bal Panchayat:</em></strong> Bal Panchayat, similar to the Gram Panchayat, is the institution of all the children in the village,
        and it can contribute meaningfully to the GPDP process. Bal Sansad should also be part of Bal Panchayat.
        The main purpose of the Bal Panchayat is to fulfil the children’s right to participation in the matters that affect them, and GPDP is the prime process,
        tool and mechanism at the GP level to ensure it.
      </React.Fragment>,
      panel: "panel6",
      ariaControl: "panel6-content",
      id: "panel6-header",
    },
  ];

  return quesArray;
}
