import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@material-ui/icons/GetApp';

//PDF files
import PDF1 from "../../res/a-Circulars/gu/Gujarati 15th Finance Commission related Circular.pdf";
import PDF2 from "../../res/a-Circulars/gu/Gujarati 15th Finance Commsn Water Sanitation Fund Advisory.pdf";
import PDF3 from "../../res/a-Circulars/gu/Gujarati GR_Pani_Samiti_2016.pdf";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 0,
  },
  contactContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  tableContainer: {
    width: "80%",
    marginLeft: "10em",
    marginRight: "10em",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2em",
      marginRight: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1.5em",
      marginRight: "1.5em",
    }
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  a: {
    color: theme.palette.common.color1,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(filename, url, date, size) {
  return { filename, url, date, size };
}

const rows = [
  createData('15માં નાણાં પંચ અંગે પરિપત્ર 15_05_2020', PDF1, "May 15, 2020", "4,978 KB"),
  createData('15માં નાણાં પંચ સંબંધિત પાણી અને સ્વચ્છતા અંગે પરિપત્ર 14_05_2020', PDF2, "May 14, 2020", "3,326 KB"),
  createData('પાણી સમિતિની રચના અંગે ઠરાવ 13_04_2016', PDF3, "Apr 13, 2016", "4,087 KB"),
];

export default function GPCircularsPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (

    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item className={classes.contactContainer}> {/*----------Contact Us Block-----------*/}
        <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>ગ્રામ પંચાયત પરિપત્રો</span></Typography>
        <br/><br/>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">ડાઉનલોડ</StyledTableCell>
              <StyledTableCell width="60%">ફાઈલનું નામ</StyledTableCell>
              <StyledTableCell align="right" width="20%">તારીખ</StyledTableCell>
              <StyledTableCell align="right" width="15%">સાઈઝ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.filename}>
                <StyledTableCell>
                  <IconButton color="primary" aria-label="Download File" className={classes.iconButton} >
                    <a href = {row.url} target = "_blank" rel="noreferrer" className={classes.a}><DownloadIcon /></a>
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell >
                  {row.filename}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.size}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
