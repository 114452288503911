import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@material-ui/icons/GetApp';

//PDF files
import PDF1 from "../res/a-Circulars/15th Finance Commission Water Sanitation Fund Advisory English 17 March 2020.pdf";
import PDF2 from "../res/a-Circulars/15th Finance Commission Letter 20 February 2020.pdf";
import PDF3 from "../res/a-Circulars/Child Friendly GP Award Criteria Letter of 8Mar19.pdf";

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: 0,
  },
  contactContainer: {
    padding: "3em 10em",
    [theme.breakpoints.down("md")]: {
      padding: "3em 2em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "3em 1.5em",
    }
  },
  tableContainer: {
    width: "80%",
    marginLeft: "10em",
    marginRight: "10em",
    [theme.breakpoints.down("md")]: {
      marginLeft: "2em",
      marginRight: "2em",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1.5em",
      marginRight: "1.5em",
    }
  },
  table: {
    minWidth: 700,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  a: {
    color: theme.palette.common.color1,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(filename, url, date, size) {
  return { filename, url, date, size };
}

const rows = [
  createData('15th Finance Commission Water Sanitation Fund Advisory English 17 March 2020.pdf', PDF1, "Mar 17, 2020", "9,555 KB"),
  createData('15th Finance Commission Letter 20 February 2020', PDF2, "Feb 20, 2020", "134 KB"),
  createData('Child Friendly GP Award Criteria Letter of 8Mar19', PDF3, "Mar 08, 2019", "1,451 KB"),
];

export default function GPCircularsPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (

    <Grid container direction="column" className={classes.mainContainer}>
      <Grid item className={classes.contactContainer}> {/*----------Contact Us Block-----------*/}
        <Typography variant="h2"><span style={{color: theme.palette.common.color1}}>Gram Panchyat Circulars</span></Typography>
        <br/><br/>
      </Grid>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">Download</StyledTableCell>
              <StyledTableCell width="60%">Document Name</StyledTableCell>
              <StyledTableCell align="right" width="20%">Date</StyledTableCell>
              <StyledTableCell align="right" width="15%">Size</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.filename}>
                <StyledTableCell>
                  <IconButton color="primary" aria-label="Download File" className={classes.iconButton} >
                    <a href = {row.url} target="_blank" rel="noreferrer" className={classes.a}><DownloadIcon /></a>
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell >
                  {row.filename}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.size}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
