import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import ScrollToTop from './ui/ScrollToTop';

//English
import theme from './ui/Theme';
import Header from './ui/Header';
import Footer from './ui/Footer';
import LandingPage from './LandingPage';
import ResourcesPage from './ResourcesPage';
import GPCircularsPage from './GPCircularsPage';
import GPGuidlinesPage from './GPGuidlinesPage';
import SuccessStoriesPage from './SuccessStoriesPage';
import GPWinnerDetailsPage from './GPWinnerDetailsPage';
import GPAllWinnerPage from './GPAllWinnerPage';
import GPNationalWinnerPage from './GPNationalWinnerPage';
import FAQPage from './faqPage';
import AboutUsPage from './AboutUsPage';
import ContactUsPage from './ContactUsPage';
// import UnderConstruction from './ui/UnderConstruction';

//Gujarati
// import theme_gu from './gu/ui/Theme';
import HeaderGu from './gu/ui/Header';
import FooterGu from './gu/ui/Footer';
import LandingPageGu from './gu/LandingPage';
import ResourcesPageGu from './gu/ResourcesPage';
import GPCircularsPageGu from './gu/GPCircularsPage';
import GPGuidlinesPageGu from './gu/GPGuidlinesPage';
import SuccessStoriesPageGu from './gu/SuccessStoriesPage';
import GPWinnerDetailsPageGu from './gu/GPWinnerDetailsPage';
import GPAllWinnerPageGu from './gu/GPAllWinnerPage';
import GPNationalWinnerPageGu from './gu/GPNationalWinnerPage';
import FAQPageGu from './gu/faqPage';
import AboutUsPageGu from './gu/AboutUsPage';
import ContactUsPageGu from './gu/ContactUsPage';
// import UnderConstructionGu from './gu/ui/UnderConstruction';


function App() {

  const myContainer = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  }

  const pageContent = {
    flex: 1,
  }

  const [value, setValue] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [language, setLanguage] = useState("gu");


  return (
    <ThemeProvider theme={theme}>
      <div style={myContainer}>
      <BrowserRouter>
        <ScrollToTop />
        <div style={pageContent}>
        {language.localeCompare("en") === 0 ?
          <Header value={value} setValue={setValue} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setLanguage={setLanguage}/>
        :
          <HeaderGu value={value} setValue={setValue} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setLanguage={setLanguage}/>
        }
        <Switch>
          <Route exact path="/" component={() => language.localeCompare("en") ===0 ? <LandingPage setValue={setValue} setSelectedIndex={setSelectedIndex}/> : <Redirect to="/gu" />} />
        {/***** English Pages*****/}
          <Route exact path="/visvas" component={() => <LandingPage setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/resources" component={() => <ResourcesPage setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/gp-circulars" component={GPCircularsPage} />
          <Route exact path="/gp-guidlines" component={GPGuidlinesPage} />
        {/****<Route exact path="/gp-tools" component={GPToolsPage} /> ****/}
          <Route exact path="/GP-Success-Stories" component={() => <SuccessStoriesPage setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/GP-Success-Stories/GP-Details" component={GPWinnerDetailsPage} />
          <Route exact path="/All-Award-Winner-GP" component={GPAllWinnerPage} />
          <Route exact path="/National-Award-Winner-GP" component={GPNationalWinnerPage} />
        {/**** <Route exact path="/State-Award-Winner-GP" component={GPStateWinnerPage} /> ****/}
        {/**** <Route exact path="/Child-Friendly-GP" component={GPChildWinnerPage} /> ****/}
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/contact" component={ContactUsPage} />
        {/******************************************* Gujarati Pages *******************************************/}
          <Route exact path="/gu/" component={() => <LandingPageGu setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/gu/visvas" component={() => <LandingPageGu setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/gu/resources" component={() => <ResourcesPageGu setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/gu/gp-circulars" component={GPCircularsPageGu} />
          <Route exact path="/gu/gp-guidlines" component={GPGuidlinesPageGu} />
        {/**** <Route exact path="/gu/gp-tools" component={GPToolsPageGu} /> ****/}
          <Route exact path="/gu/GP-Success-Stories" component={() => <SuccessStoriesPageGu setValue={setValue} setSelectedIndex={setSelectedIndex}/>} />
          <Route exact path="/gu/GP-Success-Stories/GP-Details" component={GPWinnerDetailsPageGu} />
          <Route exact path="/gu/All-Award-Winner-GP" component={GPAllWinnerPageGu} />
          <Route exact path="/gu/National-Award-Winner-GP" component={GPNationalWinnerPageGu} />
        {/**** <Route exact path="/gu/State-Award-Winner-GP" component={GPStateWinnerPageGu} /> ****/}
        {/**** <Route exact path="/gu/Child-Friendly-GP" component={GPChildWinnerPageGu} /> ****/}
          <Route exact path="/gu/faq" component={FAQPageGu} />
          <Route exact path="/gu/about" component={AboutUsPageGu} />
          <Route exact path="/gu/contact" component={ContactUsPageGu} />
        </Switch>
        </div>
        {language.localeCompare("en") === 0 ?
          <Footer value={value} setValue={setValue} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
        :
          <FooterGu value={value} setValue={setValue} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
        }
      </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
